<template>
  <div>
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="authLogin"
      @submit="submit"
    >
      <FormulateInput
        v-model.trim="values.email"
        type="email"
        name="username"
        :label="$t('email_label')"
        autocomplete="on"
        validation="bail|required|email"
      />
      <FormulateInput
        type="password"
        name="password"
        :label="$t('password_label')"
        autocomplete="on"
        validation="required"
      />
      <FormulateErrors />
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        :input-class="['w-full md:w-auto']"
        :outer-class="['mt-7.5']"
      >
        <i
          :class="[
            'far mr-2',
            isLoading ? 'fa-spinner-third animate-spin' : 'fa-user'
          ]"
        />
        {{ $t('login') }}
      </FormulateInput>
    </FormulateForm>
    <router-link
      :to="{ name: 'AuthResetRequest' }"
      class="tg-mobile-link-small md:tg-desktop-link link"
    >
      <i class="far mr-2 fa-unlock" />
      {{ $t('request_reset_link') }}
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AuthLogin',
  data() {
    return {
      values: {}
    };
  },
  computed: {
    ...mapState('auth', ['prevRoute'])
  },
  methods: {
    ...mapActions('auth', ['login']),
    submit(data) {
      return this.login(data)
        .then(() => {
          this.$router.push(this.prevRoute || { name: 'Dashboard' });
        })
        .catch(error => {
          this.$formulate.handle(error, 'authLogin');
        });
    }
  }
};
</script>
